<template>
  <div>
    <section class="hero is-hero-bar is-purple-hero">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item is-hero-content-item">
              <div style="padding: 0 2em">
                <h1 class="title is-spaced">支払管理</h1>
              </div>
            </div>
          </div>
          <div class="level-right"></div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <CardComponent
        title="月ごと状況"
        icon="flag"
        class="tile is-child has-table"
        :has-button-slot="true"
      >
        <refresh-button
          slot="button"
          label="更新"
          :isLoading="isLoading"
          @button-click="willReFetch"
        />
        <b-table :loading="isLoading" :data="tPaymentStructs">
          <b-table-column label="対象月" v-slot="{ row }">
            <b-tooltip
              :label="row.rangeLabel"
              type="is-dark"
              position="is-right"
            >
              <p class="is-5">{{ row.ymLabel }}締め</p>
            </b-tooltip>
          </b-table-column>
          <b-table-column
            custom-key="actions"
            cell-class="is-actions-cell"
            v-slot="{ row }"
          >
            <b-button
              type="is-warning"
              size="is-small"
              icon-left="square-edit-outline"
              @click="willToSoft(row)"
              >実績管理</b-button
            >
          </b-table-column>
          <b-table-column label="実績数" numeric v-slot="{ row }">
            <span v-if="row.totalCount">{{
              row.totalCount.toLocaleString()
            }}</span>
            <span v-else />
          </b-table-column>
          <b-table-column label="未承認数" numeric v-slot="{ row }">
            <span v-if="row.confirmingCount">{{
              row.confirmingCount.toLocaleString()
            }}</span>
            <span v-else />
          </b-table-column>
          <b-table-column label="ステータス" centered v-slot="{ row }">
            <b-tag type="is-light">{{ row.fixStatus.label }}</b-tag>
          </b-table-column>
          <b-table-column label="最終計算日時" v-slot="{ row }">
            <span v-if="row.tPaymentWork">{{
              row.tPaymentWork.versionAt.format('YYYY/MM/DD HH:mm:ss')
            }}</span>
            <span v-else />
          </b-table-column>
          <b-table-column label="計算済み数" numeric v-slot="{ row }">
            <span v-if="row.stagingCount">{{
              row.stagingCount.toLocaleString()
            }}</span>
            <span v-else />
          </b-table-column>
          <b-table-column label="合計額" v-slot="{ row }" numeric>
            <span v-if="row.tPaymentWork"
              >{{ row.tPaymentWork.totalAmount.toLocaleString() }}円</span
            >
            <span v-else />
          </b-table-column>
          <b-table-column
            custom-key="actions"
            cell-class="is-actions-cell"
            v-slot="{ row }"
          >
            <div class="buttons is-right">
              <b-button
                v-if="row.tPaymentWork"
                type="is-info"
                size="is-small"
                icon-left="dock-window"
                @click="willToWork(row)"
                >計算結果</b-button
              >
            </div>
          </b-table-column>
          <section class="section" slot="empty">
            <div class="content has-text-grey has-text-centered">
              <template v-if="isLoading">
                <p>
                  <b-icon icon="dots-horizontal" size="is-large" />
                </p>
                <p>Fetching data...</p>
              </template>
              <template v-else>
                <p>
                  <b-icon icon="emoticon-sad" size="is-large" />
                </p>
                <p>Nothing's here&hellip;</p>
              </template>
            </div>
          </section>
        </b-table>
      </CardComponent>
    </section>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { mapActions, mapMutations } from 'vuex'
import RefreshButton from '@/components/RefreshButton.vue'
import { ApiPayment } from '@/api'
import { Collection } from '@/util'

export default {
  mixins: [BaseMixin],
  components: { CardComponent, RefreshButton },
  data () {
    return {
      tPaymentStructs: []
    }
  },
  methods: {
    ...mapActions('storePayment', {
      forgePaymentDatas: 'forgeDatas'
    }),
    ...mapMutations('storeCheckin', {
      clearCheckinData: 'clearData'
    }),
    willToSoft (tPaymentSoftStruct) {
      this.clearCheckinData() // 悲観的キャッシュクリア
      this.$router.push({ name: 'SoftCheckins', params: { pathPaymentYm: tPaymentSoftStruct.paymentYm.key } })
    },
    willToWork (tPaymentWork) {
      this.$router.push({ name: 'PaymentWork', params: { pathPaymentYm: tPaymentWork.paymentYmKey } })
    },
    willReFetch () {
      this.isLoading = true
      setTimeout(() => {
        ApiPayment.fetchPaymentStructs().then(({ tPaymentStructs }) => {
          this.tPaymentStructs = new Collection(tPaymentStructs).sort('paymentYmKey', 'desc').members
          this.isLoading = false
        }).catch(this.handleError)
      }, 300)
    },
  },
  created () {
    this.isLoading = true
  },
  mounted () {
    this.willReFetch()
  }
}
</script>
